
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PageSellerPageElCard extends Vue {
    @Prop({ default: true }) canClick!: boolean;

    handleClick() {
        if (!this.canClick) {
            return false;
        }
        return this.$emit("click");
    }
}
